<template>
  <div class="container-fluid" v-if="!getShowClaroFieldsComputed">
    <div class="mt-3">
      <div class="container">
        <b-card class="shadow border-1" style="background-color: #f3f3f3">
          <img
            src="@/assets/icons/card.svg"
            class="img-auto"
            alt="Responsive image"
          />
          <span> Cartão de crédito </span>
          <div class="row">
            <div class="form-adress">
              <img
                :src="form.cardFlag"
                alt=""
                class="img-fluid mt-2"
                style="margin-left: 1.5px; margin-bottom: 10px"
              />
            </div>
            <div class="col-12 col-sm-6">
              <span>Número do cartão crédito*</span>
              <input
                type="text"
                label="Número do cartão"
                class="form-control"
                placeholder="Número do cartão"
                v-model="form.cardNumber"
                v-mask="'#### #### #### ####'"
                @input="detectCardFlag()"
                @keyup="detectCardBrand()"
                @keypress="validateForm('cardNumber')"
                @paste="onPasteCreditCardHandler"
                @focusout="validateForm('cardNumber')"
              />
              <small class="form-text text-danger">{{res.getErrors("cardNumber")[0]}}</small>
            </div>
            <div class="col-12 col-sm-6">
              <span>Nome impresso no cartão*</span>
              <input
                type="text"
                label="Nome impresso no cartão"
                class="form-control"
                placeholder="Nome no cartão"
                v-model="form.cardHolder"
                @input=" validateForm('cardHolder')"
              />
              <small class="form-text text-danger">{{res.getErrors("cardHolder")[0]}}</small>
            </div>
            <div class="col-12 col-sm-6">
              <span>Validade*</span>
              <input
                type="text"
                label="Validade"
                class="form-control"
                placeholder="MM/AAAA"
                v-model="form.cardExpirationDate"
                @input="validateForm('cardExpirationDate')"
                v-mask="'##/####'"
              />
              <small class="form-text text-danger">{{res.getErrors("cardExpirationDate")[0] }}</small>
            </div>
            <div class="col-12 col-sm-6">
              <span>Código de segurança*</span>
              <input
                type="text"
                label="Código de segurança"
                class="form-control"
                placeholder="CVV"
                v-model="form.cardCVV"
                v-mask="'###'"
                @input="validateForm('cardCVV')"
              />
              <small class="form-text text-danger">{{res.getErrors("cardCVV")[0]}}</small>
            </div>
            <div class="col-12 col-sm-6">
              <span>Número de parcelas*</span>
              <b-form-select
                v-model="form.numberInstallments"
                :options="options"
                class="form-control"
                @input="validateForm('numberInstallments')">
              </b-form-select>
            </div>
            <small class="form-text text-danger">{{res.getErrors("numberInstallments")[0]}}</small>
          </div>
        </b-card>
      </div>
    </div>
  </div>
  <!----------------- FORM PAYMENT CLARO-------------------->
  <div class="mt-3" v-if="getShowClaroFieldsComputed">
    <div class="container">
      <b-card class="shadow border-1" style="background-color: #f3f3f3">
        <img
          src="@/assets/img/companies/McAfee/McAfeeCommon/Board.svg"
          class="img-auto mx-1"
          alt="Responsive image"
        />
        <span class="mx-1">Forma de Pagamento</span><br />
        <strong class="data-client">Por Favor, escolha a forma de pagamento</strong>
        <select
          class="form-select py-1 mt-1 style-select"
          aria-label=".form-select example"
          v-model="selectedPaymentMethodId">
          <option selected :value="null">Selecione</option>
          <option
            v-for="items in getCheckoutPaymentOptions[0]?.paymentOptions.items"
            :key="items.decodedId"
            :value="items.decodedId">
            {{ items.title }}
          </option>
        </select>
        <div v-if="selectedPaymentMethodId === 2">
          <br />
          <strong class="data-client">Por Favor, insira os dados da conta</strong>
          <br />
          <strong class="text-card-data-client" style="font-size: 15px">Banco</strong>
          <select
            class="form-select py-1 mt-1 style-select"
            aria-label=".form-select example"
            v-model="selectedBankCode">
            <option selected :value="null">Selecione</option>
            <option
              v-for="items in getProductBanks"
              :key="items.code"
              :value="items.code">
              {{ items.bankName }}
            </option>
          </select>
          <div class="row">
            <div class="col-6 col-sm-6">
              <strong class="text-card-data-client" style="font-size: 15px">Agência</strong>
              <input
                type="number"
                class="form-control text-input"
                v-model="agency"
                placeholder="0000"
              />
            </div>
            <div class="col-6 col-sm-6">
              <strong class="text-card-data-client" style="font-size: 15px">Conta</strong>
              <input
                type="number"
                class="form-control text-input"
                v-model="account"
                placeholder="00000-0"
              />
            </div>
          </div>
          <strong class="text-card-data-client" style="font-size: 15px">Dia do vencimento</strong>
          <select
            class="form-select py-1 mt-1 style-select"
            aria-label=".form-select example"
            v-model="selectedDay">
            <option selected :value="null">Selecione</option>
            <option
              v-for="items in getResidentialInvoiceDueDateList"
              :key="items.closingDay"
              :value="items.closingDay">
              {{ items.closingDay }}
            </option>
          </select ><br/>
          <strong class="data-client mt-4" >De que forma você prefere receber sua fatura?</strong>
          <div class="col-12 row mx-2">
            <div class="form-check col-12 mt-1">
              <input
                class="form-check-input"
                type="radio"
                name="radioGroup"
                :id="'flexRadioDefault2'"
                :value="'Fatura digital'"
                v-model="invoiceONe"
                @change="handleRadioSelection('Fatura digital')"
              />
              <label class="form-check-label" :for="'flexRadioDefault2'">Fatura digital</label >
            </div>
            <div class="form-check col-12">
              <input
                class="form-check-input"
                type="radio"
                name="radioGroup"
                :id="'flexRadioDefault3'"
                :value="'Minha Claro APP'"
                v-model="invoiceTwo"
                @change="handleRadioSelection('Minha Claro APP')"
              />
              <label class="form-check-label" :for="'flexRadioDefault3'">Minha Claro APP</label>
            </div>
            <div class="form-check col-12">
              <input
                class="form-check-input"
                type="radio"
                name="radioGroup"
                :id="'flexRadioDefault4'"
                :value="'Via Postal'"
                v-model="invoiceTree"
                @change="handleRadioSelection('Via Postal')"
              />
              <label class="form-check-label" :for="'flexRadioDefault4'" >Via Postal</label>
            </div>
          </div>
        </div>
        <div v-if="selectedPaymentMethodId === 1" class="mt-2">
          <div class="mt-4">
            <strong class="data-client">Como gostaria de receber seu boleto?</strong>
            <div class="form-check col-12">
              <input
                class="form-check-input"
                type="radio"
                name="radioGroup"
                :id="'flexRadioDefault3'"
                :value="'Email'"
                v-model="invoiceBoleto"
                @change="handleRadioSelection('Email')"
              />
              <label class="form-check-label" :for="'flexRadioDefault3'">Email</label>
            </div>
          </div>
          <div class="mt-4">
            <strong class="data-client">Escolha a data de vencimento.</strong>
            <select
              class="form-select py-1 mt-1 style-select mt-2"
              aria-label=".form-select example"
              v-model="selectedDay">
              <option selected :value="null">Selecione</option>
              <option
                v-for="items in getResidentialInvoiceDueDateList"
                :key="items.closingDay"
                :value="items.closingDay">
                {{ items.closingDay }}
              </option>
            </select>
          </div>
          <br />
        </div>
      </b-card>
    </div>
    <RemakeAutentica v-if="showModal"/>
  </div>
  <div class="container text-center mt-4 container-checkbox col-12">
    <Button
      tag="Concluir pedido"
      pill
      variant="success"
      type="submit"
      class="btn btn-success col-12 col-sm-4 col-md-4 col-lg-5 loading-button"
      @click="next"
      >
      <span v-if="!processingPayment">Concluir pedido</span>
      <span v-else>
        <div class="text-center">
          <strong>Aguarde o processamento......</strong>
          <div
            class="spinner-border ml-auto"
            role="status"
            aria-hidden="true"
            style="width: 16px; height: 16px">
          </div>
        </div>
      </span>
    </Button>
    <br />
    <br />
    <div class="text-center mb-3">
      <span class="ms-2 checkbox-terms">
        <img
          src="@/assets/img/companies/McAfee/McAfeeCommon/Lock.svg"
          alt="lock"
          class="img-fluid"
          style="width: 15px"
        />
        Compra segura TradeUp Group
      </span>
    </div>
  </div>
  <ModalWarning v-if="showModalWarning">
    Por favor, preencha corretamente todos os dados de pagamento!
</ModalWarning>
</template>
<script >
import MoneyService from "@/services/numbers/money";
import suite from "@/services/validations/paymentValidations.js";
import Button from "@/components/Form/Button/Button.vue";
import { mapGetters, mapActions } from "vuex";
import RemakeAutentica from "@/components/Modal/RemakeAutentica.vue";
import ModalWarning from "@/components/Modal/ModalWarning.vue";

export default {
  name: "PaymentForm",
  components: {
    Button,
    RemakeAutentica,
    ModalWarning
  },
  props: {
    checkoutPaymentItems: {
      type: Array,
      default: () => [],
    },
    checkoutPaymentDataItems: {
      type: Object,
      default: () => ({}),
    },
    setCheckoutPaymentDataItem: {
      type: Function,
      default: () => {},
    },
    nextStep: {
      type: Function,
      default: () => {},
    },
    acceptedBrands: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      showModal: false,
      showModalWarning: false,
      selectedPaymentMethodId: null,
      selectedBankCode: null,
      selectedDay: null,
      agency: null,
      account: null,
      invoiceONe: null,
      invoiceTwo: null,
      invoiceTree: null,
      invoiceType: { "tipo-de-Fatura": "" },
      invoiceBoleto: null,
      form: {},
      processingPayment: false,
      expirationDate: "",
      processing: false,
      cardFlag: "",
      cardBrand: "",
      cardNumber: "",
      cardHolder: "",
      cardExpirationDate: "",
      cardCVV: "",
      numberInstallments: "",
      res: suite.get(),
      options: [
        {
          value: null,
          text: "Selecione",
        },
        {
          value: "1",
          text: "1x",
        },
        {
          value: "2",
          text: "2x",
        },
        {
          value: "3",
          text: "3x",
        },
        {
          value: "4",
          text: "4x",
        },
        {
          value: "5",
          text: "5x",
        },
        {
          value: "6",
          text: "6x",
        },
        {
          value: "7",
          text: "7x",
        },
        {
          value: "8",
          text: "8x",
        },
        {
          value: "9",
          text: "9x",
        },
        {
          value: "10",
          text: "10x",
        },
        {
          value: "11",
          text: "11x",
        },
        {
          value: "12",
          text: "12x",
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      getShowClaroFieldsComputed: "address/getShowClaroFields",
      getCheckoutPaymentOptions: "saleFlow/checkout/getCheckoutPaymentOptions",
      getProductBanks: "saleFlow/checkout/getProductBanks",
      getResidentialInvoiceDueDateList:"saleFlow/operators/claro/getResidentialInvoiceDueDateList",
      isAuthenticateUserComputed: "auth/isAuthorizationCustomer",
      getAuthenticatorRequired: "cart/getAuthenticatorRequired",
      getOrders: "saleFlow/getOrders"
    }),
    currencyBRFormat() {
      return (value) => MoneyService.currencyBRFormat(value || 0);
    }
  },
  methods: {
    ...mapActions({
      productBanks: "saleFlow/productBanks",
      fetchResidentialInvoiceDueDateList:"saleFlow/operators/claro/fetchResidentialInvoiceDueDateList",
      checkoutPaymentOptions: "saleFlow/checkoutPaymentOptions",
      setIntentToOpenModalByApiMessage:"saleFlow/operators/claro/authenticate/setIntentToOpenModalByApiMessage",
    }),
    payment() {
      this.checkoutPaymentOptions();
    },
    banks() {
      this.productBanks();
    },
    dueDateList() {
      this.fetchResidentialInvoiceDueDateList();
    },
    handleRadioSelection(selection) {
      this.invoiceType["tipo-de-Fatura"] = selection;
    },
    disableButtonPayment() {
      this.processingPayment = true;
    },
    enableButtonPayment() {
      this.processingPayment = false;
    },
    detectCardBrand() {
      const number = this.form.cardNumber.replace(/ /g, "");
      let brand = "";
            const cardPatterns = [
        {
          pattern: /^4[0-9]{12}(?:[0-9]{3})?$/,
          brand: "visa",
          image: require("@/assets/icons/card-visa.svg"),
        },
        {
          pattern: /^5[1-5][0-9]{14}$/,
          brand: "mastercard",
          image: require("@/assets/icons/mastercard.svg"),
        },
        {
          pattern: /^3[47][0-9]{13}$/,
          brand: "amex",
          image: require("@/assets/icons/card.svg"),
        },
        {
          pattern: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
          brand: "discover",
          image: require("@/assets/icons/card.svg"),
        },
        {
          pattern: /^(?:2131|1800|35\d{3})\d{11}$/,
          brand: "jcb",
          image: require("@/assets/icons/card.svg"),
        },
        {
          pattern: /^(606282\d{10}(\d{3})?)|(3841\d{15})$/,
          brand: "hipercard",
          image: require("@/assets/icons/hipercard.svg"),
        },
        {
          pattern: /^(?:5[0678]\d\d|6304|6390|67\d\d)\d{8,15}$/,
          brand: "elo",
          image: require("@/assets/icons/elo.svg"),
        },
        {
          pattern: /^(?:6011\d{12})$/,
          brand: "aura",
          image: require("@/assets/icons/card.svg"),
        },
        {
          pattern: /^(?:3(?:0[0-5]|[68][0-9])[0-9]{11})$/,
          brand: "diners",
          image: require("@/assets/icons/card.svg"),
        },
        {
          pattern: /^(?:5[1-5][0-9]{14}|2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12})$/,
          brand: "maestro",
          image: require("@/assets/icons/card.svg"),
        },
        {
          pattern: /^636[0-9]{13}$/,
          brand: "sorocred",
          image: require("@/assets/icons/card.svg"),
        },
        {
          pattern: /^6[0-9]{15}$/,
          brand: "cabal",
          image: require("@/assets/icons/card.svg"),
        },
        {
          pattern: /^50[0-9]{14,17}$/,
          brand: "hiper",
          image: require("@/assets/icons/card.svg"),
        },
        {
          pattern: /^627416[0-9]{10}$/,
          brand: "credsystem",
          image: require("@/assets/icons/card.svg"),
        },
        {
          pattern: /^603182[0-9]{10}$/,
          brand: "banescard",
          image: require("@/assets/icons/card.svg"),
        },
        {
          pattern: /^636[0-9]{13,16}$/,
          brand: "credz",
          image: require("@/assets/icons/card.svg"),
        }
      ];
      for (const cardPattern of cardPatterns) {
        if (cardPattern.pattern.test(number)) {
          brand = cardPattern.brand;
          break;
        }
      }
      this.brand;
      this.form.cardBrand =  brand || require("@/assets/icons/cardEmpty.svg");
    },
    detectCardFlag() {
      const number = this.form.cardNumber.replace(/ /g, "");
      let image = "";
      const cardPatterns = [
        {
          pattern: /^4[0-9]{12}(?:[0-9]{3})?$/,
          brand: "visa",
          image: require("@/assets/icons/card-visa.svg"),
        },
        {
          pattern: /^5[1-5][0-9]{14}$/,
          brand: "mastercard",
          image: require("@/assets/icons/mastercard.svg"),
        },
        {
          pattern: /^3[47][0-9]{13}$/,
          brand: "amex",
          image: require("@/assets/icons/card.svg"),
        },
        {
          pattern: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
          brand: "discover",
          image: require("@/assets/icons/card.svg"),
        },
        {
          pattern: /^(?:2131|1800|35\d{3})\d{11}$/,
          brand: "jcb",
          image: require("@/assets/icons/card.svg"),
        },
        {
          pattern: /^(606282\d{10}(\d{3})?)|(3841\d{15})$/,
          brand: "hipercard",
          image: require("@/assets/icons/hipercard.svg"),
        },
        {
          pattern: /^(?:5[0678]\d\d|6304|6390|67\d\d)\d{8,15}$/,
          brand: "elo",
          image: require("@/assets/icons/elo.svg"),
        },
        {
          pattern: /^(?:6011\d{12})$/,
          brand: "aura",
          image: require("@/assets/icons/card.svg"),
        },
        {
          pattern: /^(?:3(?:0[0-5]|[68][0-9])[0-9]{11})$/,
          brand: "diners",
          image: require("@/assets/icons/card.svg"),
        },
        {
          pattern: /^(?:5[1-5][0-9]{14}|2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12})$/,
          brand: "maestro",
          image: require("@/assets/icons/card.svg"),
        },
        {
          pattern: /^636[0-9]{13}$/,
          brand: "sorocred",
          image: require("@/assets/icons/card.svg"),
        },
        {
          pattern: /^6[0-9]{15}$/,
          brand: "cabal",
          image: require("@/assets/icons/card.svg"),
        },
        {
          pattern: /^50[0-9]{14,17}$/,
          brand: "hiper",
          image: require("@/assets/icons/card.svg"),
        },
        {
          pattern: /^627416[0-9]{10}$/,
          brand: "credsystem",
          image: require("@/assets/icons/card.svg"),
        },
        {
          pattern: /^603182[0-9]{10}$/,
          brand: "banescard",
          image: require("@/assets/icons/card.svg"),
        },
        {
          pattern: /^636[0-9]{13,16}$/,
          brand: "credz",
          image: require("@/assets/icons/card.svg"),
        }
      ];

      for (const cardPattern of cardPatterns) {
        if (cardPattern.pattern.test(number)) {
          image = cardPattern.image;
          break;
        }
      }

      this.image;
      this.form.cardFlag = image || require("@/assets/icons/cardEmpty.svg");
    },
    treatItemsList() {
      let list = this.form;
      if (list.cardHolder) {
        list.cardHolder = list.cardHolder
          .split(" ")
          .map(
            (name) => name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()
          )
          .join(" ");
      } else {
        list.cardHolder = "";
      }
      if (list.cardExpirationDate && list.cardExpirationDate.length === 7) {
        const year = list.cardExpirationDate.slice(3, 7);
        const month = list.cardExpirationDate.slice(0, 2);
        list.cardExpirationDate = `${year}${month}`;
      } else {
        list.cardExpirationDate = "";
      }
      return list;
    },
    verifyFieldsPayment() {
      const requiredFields = {
        cardNumber: "Número do cartão",
        cardHolder: "Nome do impresso no cartão",
        cardExpirationDate: "Validade",
        cardCVV: "Código de segurança",
        numberInstallments: "Número de parcelas",
      };
      for (const field in requiredFields) {
        if (!this.form[field]?.trim()) {
          this.warningModal();
          return false;
        }
      }
      return true;
    },

    verifyFieldsStep() {
      if (this.verifyFieldsPayment()) {
        this.disableButtonPayment();
        return true;
      }
      return;
    },
    validateDebitFields() {
      const selectedPaymentMethodId = this.getCheckoutPaymentOptions[0]?.paymentOptions?.items[0]?.decodedId;
      const debitFieldsValid =
      selectedPaymentMethodId &&
        this.selectedBankCode &&
        this.agency &&
        this.account &&
        this.selectedDay &&
        this.invoiceType;
      return debitFieldsValid;
    },
    validateTicketFields() {
      const selectedPaymentMethodId = this.getCheckoutPaymentOptions[0]?.paymentOptions?.items[0]?.decodedId;
      const ticketFields =
        selectedPaymentMethodId &&
        this.invoiceBoleto &&
        this.selectedDay;
      return ticketFields;
    },
   checkOrderStatus() {
    this.clearPaymentData();
    this.enableButtonPayment();
},
    step() {
      this.nextStep();
    },
    clearPaymentData() {
       return {
         creditCard: {
          cardFlag: this.form.cardFlag = "",
          cardBrand: this.form.cardBrand = "",
          cardHolder: this.form.cardHolder = "",
          cardNumber: this.form.cardNumber = "",
          cardCVV: this.form.cardCVV = "",
          cardExpirationDate: this.form.cardExpirationDate = "",
          numberInstallments: this.form.numberInstallments = "",
        },
      };
    },
    next() {
      this.disableButtonPayment();
      if (this.validateDebitFields() || this.validateTicketFields() || this.verifyFieldsStep()) {
        if (this.getShowClaroFieldsComputed) {
          this.setCheckoutPaymentDataItem(this.checkoutPaymentItems[0] || {}, {
            productPaymentOptionId: this.checkoutPaymentItems[0]?.checkoutPayment?.paymentOptions?.items[0]?.id,
            paymentData: this.getPaymentDataPayloadClaro(),
          });
        } else {
          this.form = this.treatItemsList();
          this.setCheckoutPaymentDataItem(this.checkoutPaymentItems[0] || {}, {
            productPaymentOptionId: this.checkoutPaymentItems[0]?.checkoutPayment?.paymentOptions?.items[0]?.id,
            paymentData: this.getPaymentDataPayload(),
          });
        }
        this.step();
        } else {
          this.enableButtonPayment();
          this.warningModal();

        return
        }
    },
    warningModal() {
      this.showModalWarning = true;
    },
    validateForm(field) {
      this.res = suite(
        {
          cardBrand: this.form.cardBrand,
          cardNumber: this.form.cardNumber,
          cardHolder: this.form.cardHolder,
          cardExpirationDate: this.form.cardExpirationDate,
          cardCVV: this.form.cardCVV,
          numberInstallments: this.form.numberInstallments,
          acceptedBrands: this.acceptedBrands
        },
        field
      );
    },
    getPaymentDataPayload() {
      return {
        creditCard: {
          cardBrand: this.form.cardBrand?.toLowerCase(),
          cardHolder: this.form.cardHolder,
          cardNumber: this.form.cardNumber?.replace(/\s/g, ""),
          cardCVV: this.form.cardCVV,
          cardExpirationDate: this.form.cardExpirationDate?.replace("/", "").replace(/\s/g, ""),
          numberInstallments: this.form.numberInstallments,
        },
      };
    },
    getPaymentDataPayloadClaro() {
      return {
        payment: {
          selectedPaymentMethodId: this.selectedPaymentMethodId,
          selectedBankCode: this.selectedBankCode,
          selectedDay: this.selectedDay,
          agency: this.agency,
          account: this.account,
          invoiceType: this.invoiceType,
        },
      };
    },
    async openModalAndExecuteAction() {
      if (this.getShowClaroFieldsComputed && this.getAuthenticatorRequired) {
        this.showModal = true;
      }
    },

    onPasteCreditCardHandler(event)
    {
      const pastedData = event.clipboardData.getData('Text');
      this.form.cardNumber = pastedData;
      this.validateForm('cardNumber');
    }
  },
  mounted() {
    this.productBanks();
    this.fetchResidentialInvoiceDueDateList(this.dueDay);
    this.checkoutPaymentOptions();
    this.openModalAndExecuteAction();
  },
};
</script>
<style lang="scss" scoped>
.checkbox-terms {
  font-size: 11px;
  color: rgb(66, 65, 65);
}
.loading-button i {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: spinner 1s infinite linear;
}
@keyframes spinner {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.text-card-data-client {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.004em;
  color: #5c6670;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.data-client {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #29333d;
  flex: none;
  order: 1;
  flex-grow: 0;
}
</style>
