<template>
  <div class="container-fluid mt-2">
    <div class="row">
      <div class="col-12 col-sm-9" v-if="showMcafee">
        <span>CPF*</span>
        <input
          type="text"
          label="CPF"
          class="form-control text-input"
          placeholder="CPF"
          v-model="customer.cpf"
          v-mask="'###.###.###-##'"
          @blur="validateFormCustomer('cpf')"
          @input="validateCpf"
        />
        <span v-if="cpfError" class="error-message text-danger">{{ cpfError }}</span>
      </div>
    </div>
    <div>
      <div class="row mt-1">
        <div class="col-12 col-sm-9"  v-if="showMcafee || showClaro" >
          <span>Nome*</span>
          <input
            type="text"
            label="Nome"
            class="form-control text-input"
            placeholder="Nome"
            v-model="customer.name"
            :disabled="showClaro"
            @input="validateFormCustomer('name')"   
          />
          <small class="form-text text-danger">{{res.getErrors("name")[0]}}</small>
        </div>
      </div>
      <div class="row" v-if="showClaro">
        <div class="col-12 col-sm-9">
          <span>Data de nascimento</span>
          <input
            type="text"
            class="form-control text-input"
            placeholder="00/00/0000"
            v-model="customer.birthdate"
            :disabled="showClaro"
            @input="validateFormCustomer('birthdate')"
            v-mask="'##/##/####'"
          />
          <small class="form-text text-danger">{{res.getErrors("birthdate")[0]}}</small>
        </div>
      </div>
      <div class="row" >
        <div class="col-12 col-sm-9" v-if="hideBoth">
          <span>Nome da mãe</span>
          <input
            type="text"
            class="form-control text-input"
            placeholder="Nome da mãe"
            v-model="customer.motherName"
            @input="validateFormCustomer('motherName')"
          />
          <small class="form-text text-danger">{{res.getErrors("motherName")[0]}}</small>
        </div>
      </div>
      <div class="row mt-1"  v-if="showMcafee">
        <div class="col-12 col-sm-9">
          <span>E-mail onde você quer receber a sua licença*</span>
          <input
            type="text"
            class="form-control text-input"
            placeholder="Email"
            v-model="customer.email"
            @input="validateFormCustomer('email')"
          />
          <small class="form-text text-danger">{{res.getErrors("email")[0]}}</small>
        </div>
      </div>
      <div class="row mt-1"  v-if="showMcafee">
        <div class="col-12 col-sm-9">
          <span>Confirme seu e-mail*</span>
          <input
            type="text"
            class="form-control text-input"
            placeholder="Email"
            v-model="confirmEmail"
            @input="emailConfirm"
          />
          <span v-if="confirmError" class="form-text text-danger">{{ confirmError }}</span>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12 col-sm-9"  v-if="showMcafee">
          <span>Telefone*</span>
          <input
            type="text"
            label="Telefone"
            class="form-control text-input"
            placeholder="Telefone"
            v-model="customer.phone1"
            @input="validateFormCustomer('phone1')"
            v-mask="'(##) #####-####'"
          />
           <small class="form-text text-danger">{{res.getErrors("phone1")[0]}}</small>
        </div>
        <div class="form-adress container">
          <div class="row">
            <div class="col-12 mt-2">
              <div class="row">
                <div class="col-6 col-sm-5">
                  <span>CEP*</span>
                  <input
                    type="text"
                    label="CEP"
                    class="form-control text-input"
                    placeholder="00000-000"
                    v-model="addresses.cep"
                    v-mask="'#####-###'"
                  />
                  <small class="form-text text-danger">{{resA.getErrors("cep")[0]}}</small>
                </div>
                <div class="col-6 mt-3 col-sm-6">
                  <Button                  
                    :disabled="processingCep"
                    @click="findCep"
                    class="btn-sm btn btn-primary border-1 rounded loading-button"
                    tag="Consultar CEP">
                    <span  v-if="!processingCep"> Consultar </span>
                    <span v-else>
                      <div class="text-center">
                        <strong class="text-sm"> Consultar </strong>
                        <div
                          class="spinner-border ml-auto"
                          role="status"
                          aria-hidden="true" style="width: 16px; height: 16px;">
                        </div>
                      </div>
                    </span>
                  </Button>
                </div>
                <div class="col-12 col-sm-12 mt-2" v-if="formAble">
                  <span>Endereço*</span>
                  <input
                    type="text"
                    label="Endereço"
                    class="form-control text-input mt-2"
                    placeholder="Rua, Avenida, etc..."
                    v-model="filledAddress.streetName"
                    @input="validateFormAddress('street')"
                    v-if="formAble && filledAddress"
                    disabled
                  />
                  <small class="form-text text-danger">{{resA.getErrors("street")[0]}}</small>
                </div>
                <div class="col-6 col-sm-4" v-if="formAble">
                  <span>Número*</span>
                  <input
                    type="text"
                    label="Número"
                    class="form-control text-input"
                    placeholder="Ex:123"
                    v-model="filledAddress.number"
                    @input="validateFormAddress('number')"
                    v-if="formAble && filledAddress"
                  />
                  <small class="form-text text-danger">{{resA.getErrors("number")[0]}}</small>
                </div>
                <div class="col-6 col-sm-4" v-if="formAble">
                  <span>Complemento*</span>
                  <input
                    type="text"
                    label="Complemento"
                    class="form-control text-input"
                    placeholder="Casa, Apartamento, etc..."
                    v-model="filledAddress.complement"
                    @input="validateFormAddress('complement')"                   
                    v-if="formAble && filledAddress"
                  />
                  <small class="form-text text-danger">{{resA.getErrors("complement")[0]}}</small>
                </div>          
                <div class="col-12 col-sm-6" v-if="formAble">
                  <span>Bairro*</span>
                  <input
                    type="text"
                    label="Bairro"
                    class="form-control text-input"
                    placeholder="Bairro"
                    v-model="filledAddress.neighborhoodName"
                    @input="validateFormAddress('neighborhood')"
                    v-if="formAble && filledAddress"
                    disabled
                  />
                  <small class="form-text text-danger">{{resA.getErrors("neighborhood")[0]}}</small>
                </div>
                <div class="col-12 col-sm-4" v-if="formAble">
                  <span>Cidade*</span>
                  <input
                    type="text"
                    label="Cidade"
                    class="form-control text-input"
                    placeholder="Cidade"
                    v-model="filledAddress.cityName"
                    @input="validateFormAddress('city')"
                    v-if="formAble && filledAddress"
                    disabled
                  />
                  <small class="form-text text-danger">{{resA.getErrors("city")[0]}}</small>
                </div>
                <div class="col-4 col-sm-2" v-if="formAble">
                  <span>UF*</span>
                  <b-form-select
                    v-model="filledAddress.stateName"
                    :options="stateOptions"
                    @input="validateFormAddress('state')"
                    v-if="formAble && filledAddress"
                    style="font-size: 11px;"
                    disabled
                  />
                  <small class="form-text text-danger">{{resA.getErrors("state")[0]}}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid col-12 mt-5 container-checkbox text-center">
    <span class="checkbox-terms">Ao continuar, você concorda com os <a >termos de uso.</a></span>
    <br />
    <br />
    <div class="col-12">
      <Button
        tag="Continuar"
        pill
        variant="danger"
        type="submit"
        value="Submit"
        class="btn btn-primary text-center col-12 col-sm-4 col-md-4 col-lg-5 loading-button"
        @click="nextStep"
        :disabled="processing || !isValid">
        <span v-if="!processing || !isValid">Continuar</span>
        <span v-else>
          <div class="text-center">
            <strong class="text-sm">Aguarde o processamento......</strong>
            <div
              class="spinner-border ml-auto"
              role="status"
              aria-hidden="true" style="width: 16px; height: 16px;">
            </div>
          </div>
        </span>
      </Button>
    </div>
  </div>
</template>
<script>
import suite from "@/services/validations/customerValidations.js";
import suiteA from "@/services/validations/adressValidations.js";
import { mapGetters, mapActions } from "vuex";
import Wait from "@/services/time/wait";
import Button from "@/components/Form/Button/Button.vue";
export default {
  name: "CustomerForm",
  data() {
    return {
      confirmError: "",
      confirmEmail: "",
      phoneError: '',
      cpfError: "",
      hideBoth: false,
      showClaro: false,
      showMcafee: false,
      showingClaro: false,
      processingCep: false,
      processing: false,
      customer: {
        cpf: "",
        name: "",
        birthdate: "01/01/1970",
        email: "",
        phone1: "",
        motherName: "não informado",
        id: ""
      },
      addresses: {
        cep: "",
        streetName: "",
        number: "",
        complement: "",
        neighborhoodName: "",
        cityName: "",
        stateName: "",
        id: ""
      },
      formAble: false,

      customerAddress: {
        customerId: "",
        addressId: "",
        number: "",
        complement: "",
      },
      pointOfSale: {
        id: ""
      },
      tagOrigin: "Fluxo de vendas OnLine  TradeAppOne",
      saleOfServiceId: null,
      stateOptions: [
        {value:"AC" , text:"AC"},
        {value:"AL" , text:"AL"},
        {value:"AP" , text:"AP"},
        {value:"AM" , text:"AM"},
        {value:"BA" , text:"BA"},
        {value:"CE" , text:"CE"},
        {value:"DF" , text:"DF"},
        {value:"ES" , text:"ES"},
        {value:"GO" , text:"GO"},
        {value:"MA" , text:"MA"},
        {value:"MT" , text:"MT"},
        {value:"MS" , text:"MS"},
        {value:"MG" , text:"MG"},
        {value:"PA" , text:"PA"},
        {value:"PB" , text:"PB"},
        {value:"PR" , text:"PR"},
        {value:"PE" , text:"PE"},
        {value:"PI" , text:"PI"},
        {value:"RJ" , text:"RJ"},
        {value:"RN" , text:"RN"},
        {value:"RS" , text:"RS"},
        {value:"RO" , text:"RO"},
        {value:"RR" , text:"RR"},
        {value:"SC" , text:"SC"},
        {value:"SP" , text:"SP"},
        {value:"SE" , text:"SE"},
        {value:"TO" , text:"TO"},
      ],
      zipcodes: [],
      res: suite.get(),
      resA: suiteA.get()
    };
  },
  components: {
    Button
  },
  computed: {
    isValid() {
      return (
        this.filledAddress?.streetName &&
        this.filledAddress?.number &&
        this.filledAddress?.neighborhoodName &&
        this.filledAddress?.cityName &&
        this.filledAddress?.stateName &&
        this.customer?.cpf &&
        this.customer?.name &&
        this.customer?.email &&
        this.customer?.phone1 &&
        this.emailConfirm(this.confirmEmail) &&
        this.isValidCpf(this.customer?.cpf)
      );
    },
    ...mapGetters({
      filledAddress: "address/getAddresses",
      hasCheckoutProductThatRequireClaroAuthenticate: 'saleFlow/cart/hasCheckoutProductThatRequireClaroAuthenticate',  
    }),
    ...mapGetters({
      customerComputed: "customer/getCustomer",
      customerAddressComputed: "customer/getCustomerAddress",
      hasAvailableProductTypeClaroComputed:"saleFlow/product/hasAvailableProductTypeClaro",
      requireShowModalAuthenticateComputed:"saleFlow/operators/claro/authenticate/requireShowModalAuthenticate",
      hasAvailableProductTypeMcafeeComputed:"saleFlow/product/hasAvailableProductTypeMcafee",
      isValidClaroAuthenticateLinkComputed: "saleFlow/operators/claro/authenticate/isValidLink",
      getShowClaroFieldsComputed: "address/getShowClaroFields"
    }),
      showModal() {
        return this.hasCheckoutProductThatRequireClaroAuthenticate;
    },
  }, 
  methods: {
    ...mapActions({
      customerAssociateAddressAction: "customer/associateAddress",
      customerSaveAction: "customer/save",
      addCustomerAction: "saleFlow/addCustomer",
      getProductsCatalogAction: "saleFlow/getProductsCatalog",
      checkoutPaymentOptionsAction: "saleFlow/checkoutPaymentOptions",
      validateIfAllCartProductsCanBeSoldAction:"saleFlow/validateIfAllCartProductsCanBeSold",
      processCartToCheckoutAction: "saleFlow/processCartToCheckout",
      openModalClaroAuthenticateAction:"saleFlow/operators/claro/authenticate/openModal",
      generateClaroAuthenticateLinkAction:"saleFlow/operators/claro/authenticate/generateLink"
    }),
    findCep() {
      this.disableButtonCep();
      this.enableForm();
      this.$store.dispatch("address/findByPostalCode", this.addresses.cep)
        .then(() => {
          this.enableButtonCep();
        })
        .catch((error) => {
          this.$swal(`Erro ao buscar CEP: ${error}`);
          this.enableButtonCep();
        });
    },
    validateFormAddress(field) {
      this.resA = suiteA(
        {
          cep: this.addresses.cep,
          street: this.filledAddress.streetName,
          number: this.filledAddress.number,
          complement: this.filledAddress.complement,
          state: this.filledAddress.stateName,
          neighborhood: this.filledAddress.neighborhoodName,
          city: this.filledAddress.cityName,
          id: this.filledAddress.id,
        },
        field
      );
    },
    enableForm() {
      const {
        streetName,
        number,
        complement,
        neighborhoodName,
        cityName,
        stateName,
      } = this.addresses;
      this.formAble = !(
        streetName &&
        number &&
        complement &&
        neighborhoodName &&
        cityName &&
        stateName
      )
        ? true
        : false;
    },
    disableButton() {
      this.processing = true;
    },
    enableButton() {
      this.processing = false;
    },
    disableButtonCep() {
      this.processingCep = true;
    },
    enableButtonCep() {
      this.processingCep = false;
    },
    nextStep() {
      this.disableButton();
      this.treatItemsByApi();
      this.customerSaveAction(this.getCustomerPayload())
        .then(() =>
          this.customerAssociateAddressAction(this.getAssociateAddressPayload())
        )
        .then(() => this.addCustomerAction(this.customerComputed))
        .then(() => this.getProductsCatalogAction())
        .then(async () => {
          const validation =
            await this.validateIfAllCartProductsCanBeSoldAction();
          if (validation?.validated === true) {
            return Promise.resolve();
          }
          return Promise.reject("Cart has product that can't be sold");
        })
        .then(() => this.processCartToCheckoutAction())
        .then(() => {
          if (this.showModal == true) {
            return this.openModalClaroAuthenticateAction();
          }
          return Promise.resolve();
        })
        .then(async () => {
          await Wait.waitFor(1000); 
          return this.checkoutPaymentOptionsAction();
        })
        .then(() => this.$router.push({ name: "PaymentStep" }))
        .catch((error) => {
          this.enableButton();
          this.$swal(
            "Ocorreu um erro de processamento, por favor, tente novamente.",this.$router.push({ path: '/' }),
            " ",
            "error"
          );
        });
    },
  treatItemsByApi() {
  if (this.customer.cpf) {
    this.customer.cpf = this.customer.cpf.replace(/\D/g, '');
    }
   if(this.customer.phone1){
     this.customer.phone1 = this.customer.phone1.replace(/\D/g, '');    
     return this.customer.phone1 
  }
  
  if (this.customer.birthdate) {
    const [day, month, year] = this.customer.birthdate.split("/");
    this.customer.birthdate = `${year}-${month}-${day}`;
  }
},


  validateCpf() {
  const cpfRegex = /^[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}-?[0-9]{2}$/;
  if (!cpfRegex.test(this.customer.cpf)) {
    this.cpfError = 'CPF inválido. Por favor, insira um CPF válido.';
  } else if (!this.isValidCpf(this.customer.cpf)) {
    this.cpfError = 'CPF inválido. Por favor, insira um CPF válido.';
  } else {
    this.cpfError = '';
  }
    },
  isValidCpf(cpf) {
  cpf = cpf.replace(/\D/g, '');
  if (cpf.length !== 11 || /^(.)\1{10}$/.test(cpf)) return false;

  const calculateDigit = (slice) => {
    let sum = 0;
    for (let i = 0; i < slice.length; i++) {
      sum += parseInt(slice[i]) * (slice.length + 1 - i);
    }
    let remainder = sum % 11;
    return remainder < 2 ? 0 : 11 - remainder;
  };
  const digit1 = calculateDigit(cpf.slice(0, 9));
  const digit2 = calculateDigit(cpf.slice(0, 10));

  return parseInt(cpf.charAt(9)) === digit1 && parseInt(cpf.charAt(10)) === digit2;
},
  
 formatPhone(phone1) {
  const cleaned = phone1.replace(/\D/g, '');
  
  if (cleaned.length === 11) {
    const trimmed = cleaned.substring(2);
    return `(${trimmed.substring(0, 2)}) ${trimmed.substring(2, 7)}-${trimmed.substring(7, 11)}`;
  } else {
    return phone1;
  }
},


    getCustomerPayload() {
      return {
        cpf: this.customer.cpf,
        name: this.customer.name,
        email: this.customer.email,
        phone: '+55' + this.customer.phone1,
        motherName: this.customer.motherName,
        birthdate: this.customer.birthdate,
        id: this.customer.id,
      };
    },
    getAssociateAddressPayload() {
      return {
        customerId: this.customerComputed?.id,
        addressId: this.filledAddress?.id,
        number: this.filledAddress?.number,
        complement: this.filledAddress?.complement,
      };
    },
    validateFormCustomer(field) {
      this.res = suite(
        {
          cpf: this.customer.cpf,
          name: this.customer.name,
          birthdate: this.customer.birthdate,
          email: this.customer.email,
          phone1: this.customer.phone1,
        },
        field
      );
    },
    verifyFieldsAddress() {
      const requiredFields = [
        this.filledAddress.streetName,
        this.filledAddress.number,
        this.filledAddress.complement,
        this.filledAddress.neighborhoodName,
        this.filledAddress.cityName,
        this.filledAddress.stateName,
      ];
      const hasEmptyField = requiredFields.some((field) => {
        return !this[field] || this[field] === "";
      });

      return !hasEmptyField;
    },
    verifyFieldsStep() {
      if (!this.verifyFieldsAddress()) {
        this.enableButton();
        this.$swal(
          "Por favor, preencha todos os campos de endereço.",
          " ",
          "error"
        );
        return;
      }
      if (!this.number || this.number.trim() === "") {
        this.$swal('Por favor, preencha o campo "number".', " ", "error");
        return false;
      }
      if (!this.complement || this.complement.trim() === "") {
        this.$swal('Por favor, preencha o campo "complement".', " ", "error");
        return false;
      }
      this.disableButton();
      return true;
    },
    getDataCustomer() { 
      if(this.getShowClaroFieldsComputed !== false  ){
        this.showClaro = true;
        const customer = this.$store.getters["customer/getCustomer"];
          if (customer !== null) {
            this.customer.name = customer.name;
            this.customer.email = customer.email;
            this.customer.phone1 = this.formatPhone(customer.phone1);
            this.customer.birthdate = customer.birthdate;
            this.customer.motherName = customer.motherName;
            this.customer.id = customer.id;
            this.validateFormCustomer("cpf");
          }
      }else{
        this.showMcafee = true;
        const customer = this.$store.getters["customer/getCustomer"];
        this.customer.name = "";
        this.customer.email = "";
         this.customer.phone1 = "";
        this.customer.id = "";
      }     
    },
    emailConfirm() {
      if (this.customer.email !== this.confirmEmail) {
        this.confirmError = "Confirmação de e-mail inválida.";
        return false;
      }
      this.confirmError = "";
      return true;
    },
  }, 
    created() {
      this.getDataCustomer(); 
    },
};
</script>

<style lang="scss" scoped>
.is-invalid {
  border-color: red;
}
.error-message {
  font-size: 0.7rem;
}
.form-adress {
  margin-top: 20px;
  margin-left: 0px;
  margin-right: 0px;
  color: darkgray;
  font-size: 12px;
}
.text-form {
  font-size: 14px;
  color: rgb(88, 87, 87);
  align-items: center;
  justify-self: center;
  font-weight: bold;
}
.checkbox-terms {
  margin-left: 5px;
  font-size: 11px;
  color: rgb(66, 65, 65);
}
.container-checkbox {
  margin-bottom: 100px;
}
.text-input {
  font-size: 11px;
  color: black;
}
.text-cep {
  font-size: 10px;
}.loading-button i {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: spinner 1s infinite linear;
}
@keyframes spinner {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
  </style>




